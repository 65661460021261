<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">网上展览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/exhibition/list' }">展览管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{exhibitionTitle}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/exhibition/content/' + this.$route.params.m_id }">内容设置</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/exhibition/content/' + this.$route.params.m_id + '/meeting'}">会议管理</el-breadcrumb-item>
      <el-breadcrumb-item>编辑会议</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form class="middle-form" ref="pageRef" :model="pageForm" label-width="120px">
        <el-form-item label="排序" prop="sort">
          <el-input type="number" v-model="pageForm.sort" placeholder="数字越大越靠前"></el-input>
        </el-form-item>
        <el-form-item label="会议标题" prop="title">
          <el-input v-model="pageForm.title" placeholder="请输入会议标题"></el-input>
        </el-form-item>
        <el-form-item label="会议缩略图" prop="thumbnail">
          <upload :url="pageForm.thumbnail" :filed="'thumbnail'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="会议开始时间" prop="start_time">
          <el-date-picker v-model="pageForm.start_time" value-format="timestamp" type="datetime" placeholder="选择会议开始日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="会议结束时间" prop="end_time">
          <el-date-picker v-model="pageForm.end_time" value-format="timestamp" type="datetime" placeholder="选择会议结束日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="会议滚动图" prop="banner">
          <upload :urlArr="pageForm.banner" :filed="'banner'" :max-num="5" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="会议地址" prop="address">
          <el-input v-model="pageForm.address" placeholder="请输入会议地址"></el-input>
        </el-form-item>
        <el-form-item label="会议介绍" prop="desc">
          <QuillBar v-model="pageForm.desc" :contenttext="pageForm.desc" @inputChange="descChange($event)"></QuillBar>
        </el-form-item>
        <el-form-item label="会议议程" prop="agenda">
          <QuillBar v-model="pageForm.agenda" :contenttext="pageForm.agenda" @inputChange="agendaChange($event)"></QuillBar>
        </el-form-item>
        <el-form-item label="会议嘉宾列表" prop="guest">
          <el-button type="primary" class="add-new" @click="toShowAddPanel">新增会议嘉宾</el-button>
          <div class="guest-list">
            <div class="guest-item" v-for="(item, index) in pageForm.guest" :key="index">
              <div class="left">
                <el-image :src="item.avatar"></el-image>
              </div>
              <div class="middle">
                <div class="name">{{item.name}}</div>
              </div>
              <div class="right">
                <el-button type="success" @click="editItem(index)">编辑</el-button>
                <el-button type="danger" @click="delItem(index)">删除</el-button>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">更 新</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 新增会议嘉宾弹窗 -->
    <el-dialog title="新增嘉宾" :visible.sync="addVisible" width="500px" :before-close="addClose">
      <el-form ref="addRef" :model="addForm" label-width="120px">
        <el-form-item label="嘉宾头像" prop="avatar">
          <upload :url="addForm.avatar" :filed="'avatar'" :max-num="1" @checked-files="addCheckedFiles" @del-select="addDelSelect"></upload>
        </el-form-item>
        <el-form-item label="嘉宾姓名" prop="name">
          <el-input v-model="addForm.name" placeholder="请输入嘉宾姓名"></el-input>
        </el-form-item>
        <el-form-item label="嘉宾介绍" prop="profile">
          <QuillBar v-model="addForm.profile" :contenttext="addForm.profile" @inputChange="addProfileChange($event)"></QuillBar>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAdd">取 消</el-button>
        <el-button type="primary" @click="confirmAdd">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑会议嘉宾弹窗 -->
    <el-dialog title="修改嘉宾信息" :visible.sync="editVisible" width="500px" :before-close="editClose">
      <el-form ref="editRef" :model="editForm" label-width="120px">
        <el-form-item label="嘉宾头像" prop="avatar">
          <upload :url="editForm.avatar" :filed="'avatar'" :max-num="1" @checked-files="editCheckedFiles" @del-select="editDelSelect"></upload>
        </el-form-item>
        <el-form-item label="嘉宾姓名" prop="name">
          <el-input v-model="editForm.name" placeholder="请输入嘉宾姓名"></el-input>
        </el-form-item>
        <el-form-item label="嘉宾介绍" prop="profile">
          <QuillBar v-model="editForm.profile" :contenttext="editForm.profile" @inputChange="editProfileChange($event)"></QuillBar>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelEdit">取 消</el-button>
        <el-button type="primary" @click="confirmEdit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Upload from '@/components/common/Upload'
import QuillBar from '@/components/common/QuillBar'
export default {
  name: 'Edit',
  components: {
    Upload,
    QuillBar
  },
  data () {
    return {
      exhibitionTitle: '',
      pageForm: {
        m_id: this.$route.params.item_mid,
        sort: 0,
        title: '',
        thumbnail: '',
        start_time: '',
        end_time: '',
        banner: [],
        address: '',
        desc: '',
        agenda: '',
        guest: []
      },
      pageRules: {
        title: [
          { required: true, message: '请填写会议标题', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        thumbnail: [
          { required: true, message: '请上传会议缩略图', trigger: 'blur' }
        ],
        banner: [
          { required: true, message: '请上传会议滚动图', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入会议介绍', trigger: 'blur' }
        ]
      },
      addVisible: false,
      addForm: {
        avatar: '',
        name: '',
        profile: ''
      },
      editVisible: false,
      editForm: {
        index: '',
        avatar: '',
        name: '',
        profile: ''
      }
    }
  },
  created () {
    this.getPageData()
    this.getExhibitionTitle()
  },
  methods: {
    async getPageData () {
      const { data: res } = await this.$http.get('/exhibition-meeting', { params: { m_id: this.$route.params.item_mid } })
      if (res.status === 200) {
        this.pageForm.m_id = res.data.m_id
        this.pageForm.sort = res.data.sort
        this.pageForm.title = res.data.title
        this.pageForm.thumbnail = res.data.thumbnail
        this.pageForm.start_time = parseInt(res.data.start_time)
        this.pageForm.end_time = parseInt(res.data.end_time)
        this.pageForm.banner = res.data.banner
        this.pageForm.address = res.data.address
        this.pageForm.desc = res.data.desc
        this.pageForm.agenda = res.data.agenda
        this.pageForm.guest = res.data.guest
      } else {
        this.$message.error(res.msg)
      }
    },
    onSubmit () {
      this.$refs.pageRef.validate(async (valid, item) => {
        if (valid) {
          const { data: res } = await this.$http.put('/exhibition-meeting', this.pageForm)
          if (res.status === 200) {
            this.$message.success(res.msg)
            this.$refs.pageRef.resetFields()
            await this.$router.push('/admin/apps/exhibition/content/' + this.$route.params.m_id + '/meeting')
          }
        } else {
          this.$message.error('请检查表单必填项')
          return false
        }
      })
    },
    cancel () {
      this.$router.push('/admin/apps/exhibition/content/' + this.$route.params.m_id + '/meeting')
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.pageForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.pageForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.pageForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.pageForm[field].splice(checkedIndex, 1)
      }
    },
    getExhibitionTitle () {
      this.exhibitionTitle = window.sessionStorage.getItem('ex_name')
    },
    descChange (val) {
      this.pageForm.desc = val
    },
    agendaChange (val) {
      this.pageForm.agenda = val
    },
    editItem (index) {
      this.editForm.index = index
      this.editForm.avatar = this.pageForm.guest[index].avatar
      this.editForm.name = this.pageForm.guest[index].name
      this.editForm.profile = this.pageForm.guest[index].profile
      this.editVisible = true
    },
    delItem (index) {
      this.pageForm.guest.splice(index, 1)
    },
    addClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    editClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    // 上传组件返回值
    addCheckedFiles (data) {
      this.addForm[data.filed] = data.url
    },
    // 删除已选择文件
    addDelSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.addForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.addForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.addForm[field].splice(checkedIndex, 1)
      }
    },
    // 上传组件返回值
    editCheckedFiles (data) {
      this.editForm[data.filed] = data.url
    },
    // 删除已选择文件
    editDelSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.editForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.editForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.editForm[field].splice(checkedIndex, 1)
      }
    },
    addProfileChange (val) {
      this.addForm.profile = val
    },
    editProfileChange (val) {
      this.editForm.profile = val
    },
    cancelAdd () {
      this.addForm.avatar = ''
      this.addForm.name = ''
      this.addForm.profile = ''
      this.$refs.addRef.resetFields()
      this.addVisible = false
    },
    cancelEdit () {
      this.editForm.index = ''
      this.editForm.avatar = ''
      this.editForm.name = ''
      this.editForm.profile = ''
      this.$refs.editRef.resetFields()
      this.editVisible = false
    },
    confirmAdd () {
      this.pageForm.guest.push(JSON.parse(JSON.stringify(this.addForm)))
      this.addForm.avatar = ''
      this.addForm.name = ''
      this.addForm.profile = ''
      this.$refs.addRef.resetFields()
      this.addVisible = false
    },
    confirmEdit () {
      this.pageForm.guest[this.editForm.index].avatar = JSON.parse(JSON.stringify(this.editForm.avatar))
      this.pageForm.guest[this.editForm.index].name = JSON.parse(JSON.stringify(this.editForm.name))
      this.pageForm.guest[this.editForm.index].profile = JSON.parse(JSON.stringify(this.editForm.profile))
      this.editForm.avatar = ''
      this.editForm.name = ''
      this.editForm.profile = ''
      this.$refs.editRef.resetFields()
      this.editVisible = false
    },
    toShowAddPanel () {
      this.addVisible = true
    }
  }
}
</script>

<style scoped>
.middle-form{
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}
/deep/.el-radio{
  margin-bottom: 14px;
}
.add-new{}
.guest-list{}
.guest-list .guest-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px dashed rgba(0,0,0,0.2);
  margin: 10px 0;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 6px;
}
.guest-list .guest-item:hover{
  background-color: rgba(255, 255, 255, 1);
}
.guest-list .guest-item .left{
  width: 100px;
  height: 100px;
  background-color: rgba(0,0,0,0.1);
}
.left .el-image{
  width: 100%;
  height: 100%;
}
.guest-list .guest-item .middle{}
.guest-list .guest-item .middle .name{}
.guest-list .guest-item .right{}
</style>
